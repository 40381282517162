import React, { useState } from 'react';
// ownedInventory is a list pulled from the DB, which consist of every item the user owns
// What should this function do?
// Needs to be reactive, sending new values to the game view when prompted
// So how should this be done?:
// Split the owned into blocks of 0-99, 100-199, ..., 900-999
// Current_Display is a list 10 integers long, representative of the user's settings
// When the user changes something and presses 'Save Changes'
// Send the new Current_Display to Unity, have the C# scripts generate the view based on the list

const DisplayInventory = ({ ownedInventory, onSave }) => {
    // Set default selectedItemId to 1
    const [selectedItemId, setSelectedItemId] = useState(1);

    const handleItemClick = (itemId) => {
        // If the item is already selected, keep it selected
        setSelectedItemId(prevSelectedItemId => prevSelectedItemId === itemId ? itemId : itemId);
    };

    const saveChanges = () => {
        // Ensure onSave handles null in case no item is selected
        onSave(selectedItemId);
    };

    return (
        <div>
            {ownedInventory.map((item) => (
                <div key={item.id} onClick={() => handleItemClick(item.id)} style={{cursor: 'pointer'}}>
                    {item.label}{selectedItemId === item.id ? " (Selected)" : ""}
                </div>
            ))}
            <button onClick={saveChanges}>Save Changes</button>
        </div>
    );
};


export default DisplayInventory;

// UserInfoPopup.js
import React, { useState } from 'react';
import styles from './UserInfoPopup.module.css';

const UserInfoPopup = ({ onClose, onSave }) => {
    const [userInfo, setUserInfo] = useState({ name: '', email: '', age: '' });
  
    const handleChange = (e) => {
      setUserInfo({ ...userInfo, [e.target.name]: e.target.value });
    };
  
    const handleSubmit = (e) => {
      e.preventDefault();
      console.log(userInfo);
      onSave(userInfo); // Pass the userInfo to the onSave callback
      onClose(); // Close the popup
    };
    
    return (
      <div className={styles.modalBackdrop} onClick={onClose}>
        <div className={styles.formPopup} onClick={(e) => e.stopPropagation()}>
          <form onSubmit={handleSubmit}>
            <label>Name:</label>
            <input type="text" name="name" value={userInfo.name} onChange={handleChange} required />

            <label>Email:</label>
            <input type="email" name="email" value={userInfo.email} onChange={handleChange} required />

            <label>Age:</label>
            <input type="number" name="age" value={userInfo.age || ''} onChange={handleChange} />
            
            <button type="submit">Save</button>
            <button onClick={onClose}>Cancel</button>
          </form>
        </div>
      </div>
    );
  };
  

export default UserInfoPopup;

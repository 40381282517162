import React, { useState } from 'react';
import UserInfoPopup from './UserInfoPopup'; 

const serverUrl='http://localhost:8000';

/* 
ConnectWalletButton

Parameters:
  setAccount (prop): 

Uses:
  
*/

const ConnectWalletButton = ({ setAccount }) => {
  const [showWarning, setShowWarning] = useState(false); // State to control the warning modal
  const [showUserInfoPopup, setShowUserInfoPopup] = useState(false); // state for popup visibility
  const [userKey, setUserKey] = useState(null); // State to store userKey globally

  // Function to send wallet data to server
  const sendWalletDataToServer = async (userKey) => {
    try {
      const response = await fetch(`${serverUrl}/api/saveWalletData`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ userKey }), // Send userKey to server
      });
      if (response.ok) {
        const responseData = await response.json(); // Parse the JSON response
        console.log('Wallet data sent to server successfully');

        // if first time wallet has been connected, we will initialize the user (popup getting user info)
        if (responseData.userExisted === false) {

          console.log('Get user info initiated')
          // call the get user info popup form such that user can enter their info
          console.log("Prompting user to enter user info.")

          //setShowUserInfoPopup(true); <- TODO this is the actual call, which has not been completed
        }

      } else {
        console.error('Failed to send wallet data to server:', response.statusText);
      }
    } catch (error) {
      console.error('Error sending wallet data to server:', error);
    }
  };

  const handleSaveUserInfo = async (userInfo) => {
    try {
      console.log("here");
      const response = await fetch(`${serverUrl}/api/updateUserDemographics`, { 
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ userKey, userInfo }),
      });
      const responseData = await response.json(); // Parse the JSON response
      console.log(responseData);
      console.log("here2");
      if (response.ok) {
        //const responseData = await response.json(); // Parse the JSON response
        console.log('User info sent to server successfully');

      } else {
        console.error('Failed to send user data to server:', response.statusText);
      }
    } catch (error) {
      console.error('Error sending user data to server:', error);
    }
  };

  const connectWalletHandler = async () => {
    if (window.ethereum) {
      try {
        const accounts = await window.ethereum.request({ method: 'eth_requestAccounts' });
        console.log('Connected account:', accounts[0]);
        setAccount(accounts[0]);
        setUserKey(accounts[0]);
        // After successfully connecting, send wallet data to server
        await sendWalletDataToServer(accounts[0]);
      } catch (error) {
        console.error('Error connecting to MetaMask:', error);
      }
    } else {
      setShowWarning(true); // Show the warning modal if MetaMask is not installed
    }
  };

  return (
    <>
      <button onClick={connectWalletHandler}>Connect Wallet</button>
      {showWarning && (
        <div className="modal-backdrop" onClick={() => setShowWarning(false)}>
          <div className="modal" onClick={(e) => e.stopPropagation()}>
            <p>MetaMask/Coinbase wallet is not installed. Please install it to connect your wallet.</p>
            <button onClick={() => setShowWarning(false)}>Close</button>
          </div>
        </div>
      )}
      {showUserInfoPopup && (
        <UserInfoPopup onClose={() => setShowUserInfoPopup(false)} onSave={handleSaveUserInfo} />
      )}

    </>
  );
};

export default ConnectWalletButton;

import React, { useEffect, useState } from "react";
import { Unity, useUnityContext } from "react-unity-webgl";
import ConnectWalletButton from "./components/ConnectWalletButton";
import NFTGallery from "./components/NFTGallery";
import DisplayInventory from "./components/Inventory";

function App() {
  const { unityProvider, addEventListener, removeEventListener, sendMessage } = useUnityContext({
    loaderUrl: "Build/WebGL.loader.js",
    dataUrl: "Build/WebGL.data",
    frameworkUrl: "Build/WebGL.framework.js",
    codeUrl: "Build/WebGL.wasm",
  });

  const [userAddress, setUserAddress] = useState("");

  const ownedInventory = [
    { id: 2, label: "Ice Island", icon: './components/frozenisland.png'},
    { id: 1, label: "Normal Island", icon: './components/normalisland.png'}
  ];

  // Function to update the castle based on NFT balance
  const handleNFTDataReady = (nftBalance) => {
    // Here, we send a message to Unity to generate the castle based on the NFT balance
    sendMessage("CastleGeneratorObject", "CreateCastle", nftBalance);
  };

  const handleSaveInventoryChanges = (selectedItemId) => {
    // Convert selectedItems array to a string if necessary, or directly use it depending on your Unity script expectation
    // For example, if Unity expects a comma-separated string of item IDs:
    sendMessage("InventoryManagerObject", "ChangeIsland", selectedItemId);
  };

  useEffect(() => {
    // Listener function for the CreateCastle event from Unity
    const handleCreateCastle = (eventData) => {
      // Handle the event, such as updating the state with eventData
      console.log("CastleCreated event received from Unity", eventData);
    };

    const handleUpdateInventory = (eventData) => {
      // Handle the event, such as updating the state with eventData
      console.log("InventoryUpdated event received from Unity", eventData);
    };

    // Add event listener for the CreateCastle event
    addEventListener("CastleCreated", handleCreateCastle);
    addEventListener("InventoryUpdated", handleUpdateInventory);

    // Cleanup the event listener when the component unmounts or the addEventListener function changes
    return () => {
      removeEventListener("CastleCreated", handleCreateCastle);
      removeEventListener("InventoryUpdated", handleUpdateInventory);
    };
  }, [addEventListener, removeEventListener]); // Dependencies for the useEffect hook

  return (
    <div className="app-container">
      <h1>CryptKeep</h1>
      <Unity unityProvider={unityProvider} style={{ width: "1200px", height: "600px" }} />
      <ConnectWalletButton setAccount={setUserAddress} />
      {userAddress && <NFTGallery userAddress={userAddress} onNFTDataReady={handleNFTDataReady} />}
      <DisplayInventory ownedInventory={ownedInventory} onSave={handleSaveInventoryChanges}/>
    </div>
  );
}

export default App;

import React, { useState, useEffect } from 'react';
import Web3 from 'web3';
import Swal from 'sweetalert2';

/* 
NFTGallery

Parameters:
  userAddress (prop): The user's public wallet key.
  onNFTDataReady (prop): Callback, triggered upon successfully Unity message passing.

Uses:
  Use Polygonscan API key to fetch contract ABI
  Change user chain to Polygon mainnet for successful fetching of balance info with ABI
  Send NFT data to the backend server to update DB values
*/
const serverUrl='https://cryptkeep.xyz';


const NFTGallery = ({ userAddress, onNFTDataReady }) => {
  const [nfts, setNfts] = useState([]); // Initializes state for storing NFTs, starts as empty list
  const contractAddress = "0xfE9f6E177aFe72526aceE4f1e90532938BAa51cD"; // NFT contract address
  const [contractABI, setContractABI] = useState(null); // Initializes state for contractABI, starts null

  useEffect(() => { // Hook definition
    const apiKey = "8ZGUQ6H8HJVYICRJZ8FR9NTRSQ6NBTZX7I"; // PolygonScan API key
    const fetchContractABI = async () => {
      const url = `https://api.polygonscan.com/api?module=contract&action=getabi&address=${contractAddress}&apikey=${apiKey}`;
      try {
        const response = await fetch(url); // Await result of fetching ABI
        const data = await response.json(); // Await response of .json data
        if (data.status === "1") { // If successful, update contractABI with parsed information
          setContractABI(JSON.parse(data.result));
        } else { // Successful request, data fetching unsuccessful
          console.error("Failed to fetch contract ABI:", data.message);
        }
      } catch (error) { // Unsuccessful request
        console.error("Error fetching contract ABI:", error);
      }
    };
    fetchContractABI(); // Call to fetch contractABI
  }, [contractAddress]); // contractAdress dependency, re-run fetching upon detected change

  useEffect(() => { // Hook definition
    const switchToPolygonMainnet = async () => { // Switch user's ethereum wallet the the Polygon mainnet
      try {
        await window.ethereum.request({ // Wait for the wallet to switch chain
          method: 'wallet_switchEthereumChain', // web3.js method
          params: [{ chainId: '0x89' }], // Input parameter being chainId, 0x89 being the Polygon mainnet chain
        });
      } catch (switchError) { // Display error in console if unsuccessful
        console.error("Could not switch to Polygon Mainnet:", switchError);
        Swal.fire({
          title: "Failed switch to the Polygon Mainnet",
          html: "This is typically a MetaMask 🦊 issue. <br> Please: <br> 1. Open your wallet extension <br> 2. Click on Network Switcher <br> 3. Click 'Add Network' <br> 4. Add the Polygon Mainnet",
          icon: "error",
          width: '500px',
          confirmButtonText: 'Refresh',
          customClass: {
            title: 'swal2-title',
            htmlContainer: 'swal2-html-container' // This is where you apply your custom CSS class for text size
          },
        allowOutsideClick: false}).then((result) => {
            if (result.isConfirmed) {
              switchToPolygonMainnet(); // If there was an error, and we have to resort to recursively calling this function, we should refresh the page upon success
            }
          });
        }
    }; 

    const fetchNFTBalances = async () => { // Fetch user's NFT balance
      if (!userAddress || !contractABI) return; // Necessary parameters for fetching NFT balance
      
      const web3 = new Web3(window.ethereum); // 
      const contract = new web3.eth.Contract(contractABI, contractAddress);

      try {
        await switchToPolygonMainnet(); // Await user change to Polygon mainnet
        const balance = await contract.methods.balanceOf(userAddress, 1).call(); // Wait for smartcontract querying, parameters being user pub. key and tokenId
        const updatedNfts = [{ userKey: userAddress, balance: parseInt(balance, 10) }]; // List for update after query
        setNfts(updatedNfts); // Update nfts with user key and balance
        
        if (updatedNfts.length > 0 && typeof onNFTDataReady === 'function') { // Ensure onNFTDataReady exists and is a function before calling
          onNFTDataReady(updatedNfts[0].balance);  // Call the function with the balance to inform parent component of success/failure
        console.log("NFT balance fetched successfully.");
        sendWalletDataToServer({ userKey: userAddress });
          
        }

        if (updatedNfts.length > 0) { // Ensure updatedNfts has content before attempting to send to server
          sendNFTDataToServer(updatedNfts[0]);
        }
      } catch (error) { // Unsuccessful fetching attempt
        console.error("Error fetching NFT balance:", error);
      }
    };

    if (contractABI) { // If contractABI was successfully found, fetch NFT balance from ABI
      fetchNFTBalances();
    }
  }, [userAddress, contractABI, onNFTDataReady]); // Dep. array, reinvoke hooks to update values

  const sendWalletDataToServer = async (walletData) => {
    try {
      const response = await fetch(`${serverUrl}/api/saveWalletData`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(walletData) // Send wallet data to server
      });
      if (response.ok) {
        const responseBody = await response.json();
        console.log("Wallet data sent to server successfully:", responseBody.message);
        // Optionally, update UI or state based on response
      } else {
        // Log specific error message from server or status text
        console.error("Failed to send Wallet data to server:", await response.text());
        // Optionally, update UI to reflect error state
      }
    } catch (error) {
      console.error("Error sending Wallet data to server:", error);
      // Optionally, show a user-friendly error message in the UI
    }
  };

  const sendNFTDataToServer = async (nftData) => {
    try {
      const response = await fetch(`${serverUrl}/api/saveNFTData`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(nftData) // Send NFT data to server
      });
      if (response.ok) {
        const responseBody = await response.json();
        console.log("NFT data sent to server successfully:", responseBody.message);
        // Optionally, update UI or state based on response
      } else {
        // Log specific error message from server or status text
        console.error("Failed to send NFT data to server:", await response.text());
        // Optionally, update UI to reflect error state
      }
    } catch (error) {
      console.error("Error sending NFT data to server:", error);
      // Optionally, show a user-friendly error message in the UI
    }
  };

  // Return JSX formatted data from above functions
  return (
    <div>
      <h2>My ERC-1155 NFT Collection</h2>
      {nfts.length && nfts[0].balance > 0 ? (
        <div>
          <p>Token ID: {nfts[0].userKey}</p>
          <p>Balance: {nfts[0].balance}</p>
        </div>
      ) : (
        <p>No NFTs found or balance is 0 for the queried token ID.</p>
      )}
    </div>
  );
};

export default NFTGallery;
